import React, { useState, useEffect } from 'react'

import "./style.css"

function Tutorial(props:PropsValue) {

  const [position, setPosition] = useState(null);
  const [intro_position, setIntroPosition] = useState(null);
  const [window_h, setWindowH] = useState(null);

  const offset = 10
  //const stableGroupChangeHandler = useCallback(groupChangeHandler,[])

  useEffect(() => {

    window.addEventListener('resize', () => {
        setWindowH(window.innerHeight + "_" + window.innerWidth)
    });
    return function cleanup() {

    };
  },[]);

  useEffect(() => {

    const element = window.document.getElementById(props.id)
    const rect = element.getBoundingClientRect();
    var elemtop = rect.top + window.pageYOffset - offset;
    var elemleft = rect.left + window.pageXOffset - offset;
    var width = rect.width + 2 * offset
    var height = rect.height + 2 * offset
    const current_h = (window.document.getElementById("react-tutorial-js-tooltip").getBoundingClientRect().height)

    setPosition(
      {
        top: elemtop + "px",
        left: elemleft + "px",
        width: width + "px",
        height: height + "px"
      }
    )
    setIntroPosition(
      {
        top: (elemtop - current_h - 10) + "px",
        left: elemleft + "px"
      }
    )
    element.classList.add("react-tutorial-js-display")
    return function cleanup() {

    };
  },[props.id, props.content,window_h]);

  function close(){
    window.document.getElementById(props.id).classList.remove("react-tutorial-js-display")
    props.closeHandler(props.id)
  }
  function closeAll(){
    window.document.getElementById(props.id).classList.remove("react-tutorial-js-display")
    props.closeHandler("all")
  }

  return(
    <div className="react-tutorial-js" >
      <div className="react-tutorial-js-wrap" onClick={close}></div>
      <div className="react-tutorial-js-box" style={position}></div>
      <div className="react-tutorial-js-tooltip" style={intro_position}  id="react-tutorial-js-tooltip" ><div className="is-mob close" onClick={closeAll}><i className="fas fa-times-circle"></i></div><span>{props.content}</span><div className="is-pc close" onClick={closeAll}><i className="fas fa-times-circle"></i></div></div>
    </div>
  )
}

export default Tutorial
