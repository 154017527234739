import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


var CHANGE_EVENT = "change"
var SET_START_TIME = "start_time"
var SCROLL = "scroll"
var UPDATE_X = "update_x"

var _dragging = false
var _start_date = null
var _is_enter = false
var _scroll_amount_y = 0
var _scroll_amount_x = 0
var _is_moving = false
var _current_x = 0;

var setDragging = (b) => {
  _dragging = b
}

var setStartDate = (time) => {
  _start_date = time
}

var setIsEnter = (b) => {
  _is_enter = b
}
var setScroll = (y,x) => {
  _scroll_amount_y = y
  _scroll_amount_x = x
}
var setIsMoving = (is_moving) => {
  _is_moving = is_moving
}
var setCurrentX = (current_x) => {
  _current_x = current_x
}
var MouseStore = assign({},EventEmitter.prototype,{


  getDragging(){
    return _dragging
  },

  getStartTime(){
      return _start_date
  },

  getIsEnter(){
    return _is_enter
  },

  getScrollAmountY(){
    return _scroll_amount_y
  },
  getScrollAmountX(){
    return _scroll_amount_x
  },

  getIsMoving(){
    return _is_moving
  },

  getCurrentX(){
    return _current_x
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.SET_DRAGGING:
      setDragging(action.dragging)
      MouseStore.emitChange()
    break;
    case Constants.SET_START_TIME:
      setStartDate(action.time)
      MouseStore.emitChange(SET_START_TIME)
    break;
    case Constants.SET_ENTER:
      setIsEnter(action.is_enter)
      MouseStore.emitChange()
    break;

    case Constants.SET_SCRIOLL:
      setScroll(action.y, action.x)
      MouseStore.emitChange(SCROLL)
    break;
    case Constants.SET_IS_MOVING:
      setIsMoving(action.is_moving)
    break;
    case Constants.SET_CURRENT_X:
      setCurrentX(action.current_x)
      MouseStore.emitChange(UPDATE_X)
    break;

    default:
  }


})
export default MouseStore
