import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import PersonalStore from "../stores/PersonalStore"
import {format} from "date-fns"
var GroupActions = {


  get(id){

    fetch(config.get("API_PATH") + config.get("API_GROUP") + "/" + id, {
      method: "GET",
      headers: {

      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {


      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.GROUP_LOADED,
          item:data
        })
        if(!PersonalStore.getCurrentDate()){
          AppDispatcher.dispatch({
            actionType: Constants.PERSONAL_SET_DATE,
            date:new Date(data.group.date_list.split(",")[0])
          })
        }
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_CREATE_GROUP")
        })
      }
    })
  },


  create(item,csrf){

    const  request_data = {
      "duration":Number(item.duration),
      "title":item.title,
      "comment":item.comment,
      "date_list":item.date_list.join(","),
      "personals":item.personals,
      "deadline_date":item.deadline_date ? format(item.deadline_date, "yyyy-MM-dd") : null
    }


    fetch(config.get("API_PATH") + config.get("API_GROUP"), {
      method: "POST",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {

      if(data && !data.error){
        window.location.href = config.get("CREATED_URL") + data.uid
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_CREATE_GROUP")
        })
      }
    }).catch(function(error) {

        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      });
  },
  createNew(){
    AppDispatcher.dispatch({
      actionType: Constants.GROUP_NEW,
    })
  },

  updateValue(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.GROUP_UPDATE,
      name:name,
      value:value
    })
  },

  updatePersonalValue(id, name,value) {
    AppDispatcher.dispatch({
      actionType: Constants.ITEM_PERSONAL_UPDATE,
      id:id,
      name:name,
      value:value
    })
  },
  determinSelect(id) {
    AppDispatcher.dispatch({
      actionType: Constants.DETERMIN_SELECT,
      id:id
    })
  },
  deleteSelectedTime(id,start_time) {
    AppDispatcher.dispatch({
      actionType: Constants.DELETE_SELECTED_TIME,
      id:id,
      start_time:start_time
    })
  }
}

export default GroupActions
