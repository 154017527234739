import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Group from "../vo/Group"
import Personal from "../vo/Personal"
import { format,isSameMinute,parseISO,isEqual,addMinutes} from 'date-fns'
import events from "events"
events.EventEmitter.defaultMaxListeners = 0

var CHANGE_EVENT = "change"
var CREATED = "created"



var _item = []

var _daytime_full_list = []


var createNew = () => {

  _item = new Group()
}

var setItem = (item) => {
  console.log(item)
  _item = (item)
  var personals = []
  if(item.items && item.items.length > 0){

    for(var i = 0; i < item.items.length; i++){
      var start_list = []
      if(item.items[i].start_at){
        start_list = item.items[i].start_at.split(",").map( d => {
          return parseISO(d)
        })
      }

      personals.push(new Personal({
        id:item.items[i].id,
        name:item.items[i].name,
        time_list:start_list,
        uid:item.items[i].uid,
        updated_at:item.items[i].updated_at,

      }))
    }
    //personals.push( new Personal())
    item.items = personals
    //全ての人数が揃っているコマのリスト
    GroupStore.setFullList(item)
  }else{
    _daytime_full_list = []
  }
}

var updateItem = (name,value) => {

    _item =  _item.set(name,value)
}

var setPersonal = (id, name, value) => {
  for(var i = 0; i < _item.items.length; i++){
    if(_item.items[i] && (_item.items[i].id  === id || id === null)) {
       _item.items[i] = _item.items[i].update(name,value)
    }else{
      if(name === "type"){
         _item.items[i] = _item.items[i].update(name,"static")
      }
    }
  }

}
var determinSelect = (id) => {

  for(var i = 0; i < _item.items.length; i++){
    if(_item.items[i] && _item.items[i].id  === id) {
      const list = _item.items[i].tmp_time_list

      for(var k = 0; k < list.length; k++){
        var is_find = false
        for(var t = 0 ; t < _item.items[i].time_list.length; t++){
          if(isEqual(list[k], _item.items[i].time_list[t])){
            is_find = true
          }
        }

        if(!is_find) {
           _item.items[i].time_list.push(list[k])
        }
      }
      _item.items[i] = _item.items[i].set("tmp_time_list", [])

    }
  }
  GroupStore.setFullList(_item)
}

var deletSelecteTime = (id, start_time) => {

  for(var i = 0; i < _item.items.length; i++){
    if(_item.items[i] && _item.items[i].id  === id) {
      const list = _item.items[i].time_list
      list.sort(function(a, b) {
        if (new Date(a).getTime() > new Date(b).getTime()) {
          return 1;
        } else {
          return -1;
        }
      })
      var is_find = false
      var start_index = null
      var delete_num = 0
      for(var t = 0 ; t < _item.items[i].time_list.length; t++){

        if(!is_find && !start_index && isEqual(start_time, _item.items[i].time_list[t])){ //削除し始め
          start_index = t
          is_find = true
          delete_num ++
        }else if(is_find && start_index !== null &&  isEqual(addMinutes(_item.items[i].time_list[start_index], 30 * delete_num), _item.items[i].time_list[t])){ //連続しているか
          delete_num ++
        }else{
          is_find = false
        }
      }
      _item.items[i].time_list.splice(start_index,delete_num)

    }
  }

  GroupStore.setFullList(_item)
}
var GroupStore = assign({},EventEmitter.prototype,{

  getItem(){
    return _item
  },

  getPersonal(id){

    if(_item.items && _item.items.length > 0){
      for(var i = 0; i < _item.items.length; i++){
        if(_item.items[i].id === id){
          return _item.items[i]
        }
      }
      return null
    }
    return null
  },


  getFullStartTime(){
    return _daytime_full_list
  },

  hasFull(date){
    for(var i = 0 ; i < _daytime_full_list.length; i++){
      if(date === format(_daytime_full_list[i].start_at, "yyyy-MM-dd")){
        return true
      }
    }
    return false
  },

  setFullList(item){
    var _day_list = [];

    //新規が一つも入力されていない時は一つ足してOK
    var add_count  = 0
    //if(item.items[item.items.length -1 ].time_list.length === 0) add_count = 1

    for(var i = 0; i < item.items.length; i++){
      var start_list = []

        start_list = item.items[i].time_list
        if(start_list.length === 0 ){
          add_count ++
        }else{
          for(var k = 0; k < start_list.length; k++){
           const new_start = start_list[k];
            var is_find = false
            for(var t = 0; t < _day_list.length; t++){
              const target = _day_list[t]
              if(isSameMinute(target.start_at,new_start)){
                target.count ++
                is_find = true
              }
            }
            if(!is_find){
              _day_list.push({start_at:new_start,count:1})
            }
          }
        }


    }

    _day_list.sort(function(a, b) {
      if (new Date(a.count).getTime() > new Date(b.count).getTime()) {
        return -1;
      } else {
        return 1;
      }
    })
    _daytime_full_list = []
    for(var h = 0;  h < _day_list.length; h++){
      const target = _day_list[h]
      if(target.count >= item.items.length - add_count){
        _daytime_full_list.push(target)
      }
    }
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.GROUP_UPDATE:
      updateItem(action.name, action.value)
      GroupStore.emitChange()
    break;

    case Constants.GROUP_NEW:
      createNew()
      GroupStore.emitChange()
    break;

    case Constants.GROUP_CREATED:
      setItem(action.item)
      GroupStore.emitChange(CREATED)
    break;
    case Constants.GROUP_LOADED:
      setItem(action.item)
      GroupStore.emitChange()
    break;
    case Constants.ITEM_PERSONAL_UPDATE:
      setPersonal(action.id, action.name,action.value)
      GroupStore.emitChange()
    break;
    case Constants.DETERMIN_SELECT :
      determinSelect(action.id)
      GroupStore.emitChange()
    break;
    case Constants.DELETE_SELECTED_TIME :
        deletSelecteTime(action.id, action.start_time)
        GroupStore.emitChange()
    break;
    default:
  }


})
export default GroupStore
