
const config = {

    API_CSRF:           '/csrf',
    API_GROUP:          '/groups',
    API_PERSONAL:       '/personals',
    API_QUESTION:       '/questions',
    PERSONAL_URL_WITH_URI:       process.env.REACT_APP_PERSONAL_URL ? process.env.REACT_APP_PERSONAL_URL  : 'http://localhost:3000/p/',
    API_PATH:           process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH  : 'http://localhost:5000',
    CREATED_URL:        '/g/',
    PERSONAL_URL:        '/p/',
    ERROR_CREATE_GROUP: '登録エラーが発生しました。',
    ERROR_API:           'サーバーエラーが発生しました。',

    GOOGLE_CALENDAR_ID: '473404474590-l27i00ogsbj8dtc3lsq5o4qc2u6f0v08.apps.googleusercontent.com',
    GOOGLE_CALENDAR_SECRET: '0vHBHBRN-JHjL0eLmnoEBWo1',

    ITEM_OFFSET:0,
    ITEM_HEIGHT:18

}
export default config;
