import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


var CHANGE_EVENT = "change"

var _current = new Date()


var setCurrentDate = (date) => {
  _current = date
}


var CalendarStore = assign({},EventEmitter.prototype,{

  getCurrentDate(){
    return _current
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CURRENT_DAY_UPDATE:
      setCurrentDate(action.date)
      CalendarStore.emitChange()
    break;

    default:
  }


})
export default CalendarStore
