import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Question from "../vo/Question"

var CHANGE_EVENT = "change"

var _item  = Question()


var updateItem = (name,value) => {
  _item = _item.set(name,value)
}


var QuestionStore = assign({},EventEmitter.prototype,{

  getItem(){
    return _item
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.UPDATE_QUESTION:
      updateItem(action.name, action.value)
      QuestionStore.emitChange()
    break;

    default:
  }


})
export default QuestionStore
