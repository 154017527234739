import React, { useState,useEffect,useCallback } from 'react'

import { format, startOfMonth, endOfMonth, getDay,getMonth, differenceInCalendarDays,isAfter,isSameDay,addMonths,subMonths} from 'date-fns'
import GroupStore from '../../stores/GroupStore';
import CalendarStore from '../../stores/CalendarStore';
import CalendarActions from '../../actions/CalendarActions';

import "../../styles/calendar.css"
import back from "../../images/back.svg"
import progress from "../../images/progress.svg"
function Calendar(props:PropsValue) {

  const [content , setContent] = useState(null);
  const [change_month_link, setChangeMonthLink] = useState(null)
  const stableCreateContent =  useCallback(createContent,[])

  const stableGroupChangeHandler = useCallback(groupChangeHandler,[])
  function groupChangeHandler(){
    createContent()
  }

  const stableCalendarChangeHandler = useCallback(calendarChangeHandler,[])

  useEffect(() => {
    GroupStore.addChangeListener(stableGroupChangeHandler)
    CalendarStore.addChangeListener(stableCalendarChangeHandler)
    stableCreateContent()
    return function cleanup() {
      GroupStore.removeChangeListener(stableGroupChangeHandler)
    };
  },[stableGroupChangeHandler,stableCreateContent,stableCalendarChangeHandler])

  function calendarChangeHandler(){
    createContent()
  }

  function createContent(){
    const item = GroupStore.getItem()

    if(item) {
      const current_date = CalendarStore.getCurrentDate();
      const now = new Date()
      const first_day = startOfMonth(current_date)
      const current_month = getMonth(current_date)

      const start_dif = getDay(first_day)
      const end_day = endOfMonth(current_date)
      const end_dif = 6 - getDay(end_day)
      const duration = differenceInCalendarDays( end_day ,first_day) + start_dif + end_dif
      const date_list = item.date_list ? item.date_list : []

      first_day.setDate(first_day.getDate() - (Number(start_dif) + 1))
      var counter = 0;
      setContent( (new Array(Math.round(duration / 7))).fill(null).map( w => {
        counter ++
        return <div className="week" key={"week_" + counter}>{(new Array(7)).fill(null).map( t => {
          first_day.setDate(first_day.getDate() +1)

          var selected = ""
          for(var i = 0; i < date_list.length; i++){
            if(date_list[i] === format(first_day,"yyyy-MM-dd")){
              selected = "selected"
            }
          }


          const day = format(first_day,"yyyy-MM-dd")
          const past_class =isAfter(now,first_day) &&  !isSameDay(now,first_day) ? "past" : ""
          if(current_month !== getMonth(first_day)){
            return  <div  key={"day" + format(first_day,"yyyy-MM-dd")} className={"date"}></div>
          }else{
            return <div  key={"day" + format(first_day,"yyyy-MM-dd")} className={"date week_" + String(first_day.getDay()) + " " + selected  + " " + past_class} onClick={() => changeSelect(day)}>{String(first_day.getDate())}</div>
          }

        })}
        </div>
      }))

      var _link = []
      if(getMonth(now) !== getMonth(CalendarStore.getCurrentDate())){
        _link.push(<div className="back" key="prev" onClick={()=>changeMonth(-1)}><img src={back} alt="prev month"/></div>)
      }else{
          _link.push(<div className="back" key="prev"></div>)
      }

      _link.push(<div className="current">{format(CalendarStore.getCurrentDate(),"yyyy年 M月")}</div>)
      _link.push(<div className="progress" key="progress" onClick={()=>changeMonth(1)}> <img src={progress} alt="next month" /></div>)

      setChangeMonthLink(_link)

    }

  }

  function changeMonth(num){
    if(num > 0){
      CalendarActions.setCurrentDate(addMonths(CalendarStore.getCurrentDate(),1))
    }else{
      CalendarActions.setCurrentDate(subMonths(CalendarStore.getCurrentDate(),1))
    }

  }


  function changeSelect(d){

    if(isAfter(new Date(d),new Date()) || isSameDay(new Date(d),new Date())){
        props.changeSelect(d)
    }
  }

  return(
    <div className="currendar-box">
      <div className="change-month">
        {change_month_link}
      </div>
      <div className="header week">
        <div className="date sunday">日</div>
        <div className="date">月</div>
        <div className="date">火</div>
        <div className="date">水</div>
        <div className="date">木</div>
        <div className="date">金</div>
        <div className="date saturday">土</div>
      </div>
      <div className="calendar-monthly">
        <div className="scrollable-container">
          <div className="scrollable-vertical">{content}</div>
        </div>
      </div>
    </div>
  )
}

export default Calendar
