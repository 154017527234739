
var SmoothScroll = {
  scrollHorizontal(range, target){
    var position = 0; // スクロールする位置
    var progress = 0; // 現在の進捗 0 ～ 20
    var start = target.scrollLeft
    var current_y = target.scrollTop
    var diff = range - start
    var isUp = diff <= 0

    var easeOut = function (p) { // ease-out に当てはめた値を返す
        return p * (2 - p);
    };
    return new Promise(function (resolve, reject) {
      var move = function () { // 実際にスクロールを行う
        progress++; // 進捗を進める
        position = start + (diff * easeOut(progress / 20)); // スクロールする位置を計算す
        target.scrollTo(position,current_y)

        if(( isUp && range < position) || ( !isUp && position < range  )) { // 現在位置が目的位置より進んでいなければアニメーションを続行させる
            requestAnimationFrame(move);
            return;
        }
        resolve(); // 処理の完了
      };

      requestAnimationFrame(move); // 初回呼び出し
     });
  },
  scrollHorizontalByMargin(range, target, start_x){
    var position = 0; // スクロールする位置
    var progress = 0; // 現在の進捗 0 ～ 20
    var div = 20
    var start = -1 * start_x
    var diff = range + start
    var isUp = diff <= 0

    var easeOut = function (p) { // ease-out に当てはめた値を返す
        return p * (2 - p);
    };

    return new Promise(function (resolve, reject) {
      var move = function () { // 実際にスクロールを行う
        progress++; // 進捗を進める
        position =  ( start - (diff * easeOut(progress / div))); // スクロールする位置を計算す
        target.style.marginLeft = position + "px"
        if((( isUp && range <  -1 * position) || ( !isUp && -1 * position < range  )) && div > progress ) { // 現在位置が目的位置より進んでいなければアニメーションを続行させる
            requestAnimationFrame(move);
            return;
        }
        resolve(); // 処理の完了
      };

      requestAnimationFrame(move); // 初回呼び出し
     });
  }
}

export default SmoothScroll
