import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change" // chenge evnetを定数にする


///AUTH
var _closed = []


var addClosedTutorial = (id) => {
  if(_closed.indexOf(id) < 0) {
    _closed.push(id)
  }
}




var TutorialStore = assign({},EventEmitter.prototype,{

  /*getAll(){ // 今のtodo全てを返す
    return _todos
  },*/



  hasClosed(id){
    console.log(_closed)
    return (_closed.indexOf(id) >= 0)
  },

  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.TUTORIAL_CLOSED:
      addClosedTutorial(action.id);
    break;
    default:

  }
})
export default TutorialStore
