import React, { useState , useEffect,useCallback} from 'react'
import MouseStore from "../../stores/MouseStore"

function TimeCalendarLabel(props:PropsValue) {
  const [timeline, setTimeLine] = useState(null)

  const stableScrollHandler = useCallback(scrollHandler,[])

  useEffect(() => {
      MouseStore.addChangeListener(stableScrollHandler,"scroll")
      var i = 0
      var counter = 0
      var border_class = ""
      setTimeLine(
      (new Array(25 * 2)).fill(null).map( w => {
        var h = ""
        if(counter === 0){
          counter ++
          border_class = ""

        }else{
          counter = 0
          h = (i-1) / 2 + ":00"
          border_class = "no-border"
        }
        i ++
        return <div className={"time-line-item-label " + border_class} key={"label_" + i}>{h}</div>
      })
    )
  },[stableScrollHandler]);


  function scrollHandler(){
    //window.document.getElementById("uesr_info_label").style.top = MouseStore.getScrollAmountY() + "px"
    //window.document.getElementById("timelin_label").style.left = MouseStore.getScrollAmountX() + "px"
  }


  return(
    <div>
        <div className="tlbox time_line_label time-line label" id="time_line_label">
          {timeline}
        </div>
        <div className="tlbox info-header user-info"></div>
        <div className="timeline-update-btn-bottom"></div>
    </div>

  )
}

export default TimeCalendarLabel
