import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'



var MouseActions = {

  setDragging(b){

    AppDispatcher.dispatch({
      actionType: Constants.SET_DRAGGING,
      dragging:b
    })
  },

  setIsEnter(b){
    AppDispatcher.dispatch({
      actionType: Constants.SET_ENTER,
      is_enter:b
    })
  },

  setStartTime(time){

    AppDispatcher.dispatch({
      actionType: Constants.SET_START_TIME,
      time:time
    })
  },
  setScroll(y,x){
    AppDispatcher.dispatch({
      actionType: Constants.SET_SCRIOLL,
      y:y,
      x:x
    })
  },
  setIsMoving(is_moving){
    AppDispatcher.dispatch({
      actionType: Constants.SET_IS_MOVING,
      is_moving:is_moving
    })
  },
  setCurrentX(current_x){
    AppDispatcher.dispatch({
      actionType: Constants.SET_CURRENT_X,
      current_x:current_x
    })
  }


}

export default MouseActions
