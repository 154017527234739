import { Record } from 'immutable'
import { isBefore,differenceInMinutes,addMinutes} from 'date-fns'
import MouseStore from "../stores/MouseStore"



export default class Personal extends Record({
  id:0,
  name:null,
  comment:null,
  time_list:[],
  uid:null,
  tmp_time_list:[],
  type:"static",
  updated_at:null
}) {
  update(name,value) {

    if(name === "tmp_time_list"){
      //  const list = this.time_list

        const  _s = MouseStore.getStartTime()
        const  _e = value

        var start_date = _s
        var end_date = _e
        if(isBefore(end_date,start_date)){
          start_date = _e
          end_date = _s
        }
        const num = (differenceInMinutes(end_date,start_date) / 30)
        const items = []
        for(var i = 0; i <= num; i++){
          items.push(addMinutes(start_date, i * 30))
        }

        /**
        var is_find = false
        for(var i = 0; i < list.length; i++){

          if(isSameMinute(list[i], value)){
            if( i === 0 || i < list.length -1){
              list.splice(i,1)
            }
            is_find = true
            break
          }
        }

        if(!is_find){
          list.push(value)
        }

        list.sort(function(a, b) {
          if (new Date(a).getTime() > new Date(b).getTime()) {
            return 1;
          } else {
            return -1;
          }
        }) **/

        return this.set("tmp_time_list",items)
    }else{
      return this.set(name,value)
    }
  }
}
