import React, { useState, useEffect,useCallback } from 'react'




function Input(props:PropsValue) {

    const [input, setInput] = useState(null)
    const stableChangeHandler = useCallback(changeHandler,[])

    useEffect(() => {
      switch(props.type){
        case "text":
        case "number":
        case "email":
        case "password":
          setInput(
            <input
              type={props.type}
              name={props.name}
              className={props.className}
              placeholder={props.placeholder}
              value={props.value ? props.value : ""}
              onChange={stableChangeHandler}
              disabled={props.disabled}
              maxLength={props.maxLength}
              size={props.size}
              max={props.max}
              min={props.min}
              step={props.step}
            />
          )
        break;

        case "textarea":

          setInput(
            <textarea
              name={props.name}
              className={props.className}
              onChange={stableChangeHandler}
              value={props.value ? props.value : ""}
            />
          )
        break;
        default:
      }
    },[props,stableChangeHandler]);

    function changeHandler(e){
      const target = e.target
      const error = props.type === "email" && target.validationMessage !== ""  ?  true : false
      props.onChange(target.name, target.value, error)
    }


    return(
        <div className={"input-box " + props.className }>
          {input}
        </div>
    )

}
export default Input
