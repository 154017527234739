import React from 'react'



import logo from "../../images/logo.svg"
import image_top_1 from "../../images/top_1.svg"
import image_top_2 from "../../images/top_2.svg"




import image_sp_0 from "../../images/sp_0.svg"


function Header(props:PropsValue) {


  return(
      <header className="personal" onClick={() => {window.location.href = "/"}}>
        <div className="inner">
          <div className="image_create_1 is-pc"><img src={image_top_2} alt="この1時間でMTGできるよ" /></div>
          <div className="image_sp_0 is-mob"><img src={image_sp_0} alt="この1時間でMTGできるよ" /></div>
          <div className="logo"><img src={logo} alt="スキマさがし" /></div>
          <div className="image_create_2 is-pc"><img src={image_top_1} alt="無料でつかえるよ" /></div>
        </div>

      </header>
  )
}

export default Header
