import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import ApiCalendar from 'react-google-calendar-api';


var GoogleCalendarActions = {




  auth(){

    ApiCalendar.onLoad((e) => {
      if(ApiCalendar.sign){
        AppDispatcher.dispatch({
          actionType: Constants.GCA_AITH_SET,
          auth:ApiCalendar.sign
        })
      }else{
        ApiCalendar.handleAuthClick();
      }
    });

  },


  checkAuto(){

    ApiCalendar.onLoad((e) => {
      if(ApiCalendar.sign){
        AppDispatcher.dispatch({
          actionType: Constants.GCA_AITH_SET,
          auth:ApiCalendar.sign
        })
        GoogleCalendarActions.getList()
      }
    });

  },


  getList(){
    ApiCalendar.listUpcomingEvents(100).then(({result}: any) => {
      AppDispatcher.dispatch({
        actionType: Constants.GCA_LIST_LOADED,
        list:result.items
      })
    });
  },




}

export default GoogleCalendarActions
