import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'



var CalendarActions = {

  setCurrentDate(date){
    AppDispatcher.dispatch({
      actionType: Constants.CURRENT_DAY_UPDATE,
      date:date
    })
  },



}

export default CalendarActions
