import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change" // chenge evnetを定数にする


///AUTH
var _code = null
var _message = null

/*
*  ここから処理本体を書き始める
*/


var setCode = (code) => {
  _code = code
}

var setMessage = (message) => {
  _message = message
}


var ErrorStore = assign({},EventEmitter.prototype,{

  /*getAll(){ // 今のtodo全てを返す
    return _todos
  },*/



  getCode(){
    return _code
  },

  getMessage(){
    return _message
  },


  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.API_ERROR:
      setCode(action.code);
      setMessage(action.message);
      ErrorStore.emitChange()
      break;

    default:
      // no
  }
})
export default ErrorStore
