import React from 'react'



function Footer(props:PropsValue) {


  return(
      <footer >
        <div className="inner">
          <div><a href="/question">お問い合わせ・ご要望</a></div>
          <div><a href="/rule">利用規約</a></div>
          <div><a href="/privacy">プライバシーポリシー</a></div>
          <div><a href="/company">会社情報</a></div>
          
        </div>
        <div className="copyright"><a href="http://unseek.co" rel="noopener noreferrer" target="_blank">&copy; {(new Date()).getFullYear()} YOKUSURU inc. All Right Reserved</a></div>
      </footer>
  )
}

export default Footer
