import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import GroupCreateApp from './components/GroupCreateApp'
import GroupCreatedApp from './components/GroupCreatedApp'
import PersonalApp from './components/PersonalApp'
import RulesApp from './components/RulesApp'
import PrivacyApp from './components/PrivacyApp'
import QuestionApp from './components/QuestionApp'
import SentApp from './components/SentApp'
import CompanyApp from "./components/CompanyApp"

config.set(configuration);

class App extends React.Component{


render(){
return(
    <div>
      <Switch>
        <Route exact path='/' component={GroupCreateApp} />
        <Route exact path='/rule' component={RulesApp} />
        <Route exact path='/sent' component={SentApp} />
        <Route exact path='/privacy' component={PrivacyApp} />
        <Route exact path='/question' component={QuestionApp} />
        <Route exact path='/company' component={CompanyApp} />
        <Route exact path='/g/:id' component={GroupCreatedApp} />
        <Route exact path='/p/:id' component={PersonalApp} />
      </Switch>
    </div>
)}}

ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
