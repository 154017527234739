import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import {differenceInMinutes,parseISO} from "date-fns"

const CHANGE_EVENT = "change"
const CHANGE_AUTH = "auth"

var _auth = null
var _list = []


var setAuth = (auth) => {
  _auth = (auth)
}

var setList = (list) => {
  _list = list
}


var GoogleCalendarStore = assign({},EventEmitter.prototype,{


  isAuth(){
    return _auth
  },
  getList(){
    return _list
  },
  getIsStarDayTime(target_time){

    for(var i = 0 ; i < _list.length; i++){
      const item = _list[i]
      if(item.start.dateTime){
        const dif = differenceInMinutes(parseISO(item.start.dateTime), target_time)
        if( dif >= 0 && dif < 30){
          return item
        }
      }
    }
    return null

  },

  getHasSchedule(target_time){

    for(var i = 0 ; i < _list.length; i++){
      const item = _list[i]
      if(item.start.dateTime && item.end.dateTime){
        const dif_s = differenceInMinutes(parseISO(item.start.dateTime), target_time)
        const dif_e = differenceInMinutes(target_time, parseISO(item.end.dateTime))

        if( dif_s <= 0 && dif_e <= 30){
          return true
        }
      }
    }
    return false

  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.GCA_AITH_SET:
      setAuth(action.auth)
      GoogleCalendarStore.emitChange(CHANGE_AUTH)
    break;
    case Constants.GCA_LIST_LOADED:
      setList(action.list)
      GoogleCalendarStore.emitChange()
    break;
    default:
  }


})
export default GoogleCalendarStore
