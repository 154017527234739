import React, {  } from 'react'


import Header from "./parts/Header"
import Footer from "./parts/Footer"

import "../styles/create.css"


function RulesApp(props:PropsValue) {



  return(
    <div className="wrap" >
      <Header />
      <main className="static">

        <section>
          <h1>プライバシーポリシー</h1>

          <h2>1)個人情報等の取扱いについて</h2>

<p>当サイトにおけるユーザー登録等により、皆様にご登録いただいたメールアドレス等の情報につきましては、個人情報保護の観点から厳格に管理しています。また、収集した皆様の情報の二次的使用も行ないません。</p>
　
<h2>2)広告について</h2>

<p>当サイトでは、第三者配信による広告サービス（Google AdSense 等）を利用しています。このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報「クッキー」（氏名、住所、メールアドレス、電話番号は含まれません）を使用することがあります。

なお、Google AdSense に関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、グーグル社の情報をご覧ください。</p>
　
<h2>3)アクセス解析について</h2>

<p>当サイトでは、サイトの改善を行なうため、グーグル社のGoogleアナリティクスを利用しています。Googleアナリティクスでは「クッキー」によって匿名のトラフィックデータを収集しています。<br/>

Googleアナリティクスのプライバシーとデータの共有に関しては、グーグル社の情報をご覧ください。<br/>

また、ディスプレイ広告に対応するGoogleアナリティクスの「ユーザー属性とインタレスト カテゴリに関するレポート」機能を利用しています。これによりグーグル社のインタレストベース広告のデータや第三者のユーザーデータ（年齢、性別、興味や関心など）を収集し、サイトの改善や適切な広告表示に利用しています。<br/>

なお、サイトユーザーの皆様はディスプレイ広告に対応するGoogleアナリティクス機能をオプトアウト（データ収集を無効化）することや広告設定を使用してGoogleディスプレイネットワークで表示される広告をカスタマイズすることができます。グーグル社の情報「Google アナリティクス オプトアウト アドオン」、「広告設定」をご覧ください。
</p>
<h2>4)個人情報等の外部への提供</h2>
<p>
皆様の同意を得た場合以外は、皆様の情報を第三者に提供いたしません。ただし、以下の利用に関しては、皆様の情報を第三者に提供することがあります。
</p>
<p>

1 法令に基づく場合<br/>
2 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br/>
3 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br/>
4 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
</p>
<h2>5)お問い合わせ</h2>
<p>
ご不明な点などがございましたら<a href="/question">お問い合わせフォーム</a>よりご連絡ください。　
</p>


        </section>

      </main>
        <Footer />
    </div>
  )
}

export default RulesApp
