import React, {  useState,useEffect ,useCallback, useRef} from 'react'
import {  useParams} from 'react-router-dom';
import { format, isSameDay} from 'date-fns'
import Select from 'react-select'
import SmoothScroll from "../utiles/SmoothScroll"
import Tutorial from "./intro/Tutorial"

import Header from "./parts/Header"
import Footer from "./parts/Footer"
import TimeCalendarLabel from "./parts/TimeCalendarLabel"
import TimeCalendarPerson from "./parts/TimeCalendarPerson"
//import GoogleCalendar from "./parts/GoogleCalendar"
import TimeCalendar from "./parts/TimeCalendar"
import TimeCalendarBtn from "./parts/TimeCalendarBtn"
import CsrfActions from '../actions/CsrfActions';
import GoogleCalendarActions from '../actions/GoogleCalendarActions';

import ErrorStore from '../stores/ErrorStore';
import GroupStore from '../stores/GroupStore';
import GoogleCalendarStore from '../stores/GoogleCalendarStore';
import PersonalStore from '../stores/PersonalStore';
import MouseStore from "../stores/MouseStore"
import TutorialStore from "../stores/TutorialStore"
import GroupActions from '../actions/GroupActions';
import PersonalActions from '../actions/PersonalActions';
import MouseActions from "../actions/MouseActions"
import TutorialActions from "../actions/TutorialActions"

import useOutsideClick from "./parts/useOutsideClick";
import "../styles/personal.css"
import {ja} from 'date-fns/locale'

import image2 from "../images/image_3.svg"
import image3 from "../images/image_4.svg"



function GroupCreatedApp(props:PropsValue) {

  const { id } = useParams();
  const [message, setMessage] = useState(null)
  const [times, setTimes] = useState(null)

  const [title, setTitle] = useState(null)
  const [comment, setComment] = useState(null)
  const [duration, setDuration] = useState(null)
  const [deadline_date, setDeadLineDate] = useState(null)
  const [current_date, setCurrentDate] = useState(null)

  const [selecter, setSelecter] = useState(null)
  const [date_list, setDateList] = useState([])
  //const [google_btn, setGoogleBtn] = useState(<div className="create-btn" onClick={googleBtnHandler}>Googlカレンダーから予定を表示</div>)
  //const [google_calenadr, setGoogleCalendar] = useState(null)
  //const [time_line_google_class, setTimeLineGoogleClass] = useState("")

  const [btn_right, setBtnRight] = useState(null)
  const [btn_left, setBtnLeft] = useState(null)

  const [tutolial, setTutolial] = useState(null)

  const stableCurrentDateHandler = useCallback(currentDateHandler,[])
  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableGroupChangeHandler = useCallback(groupChangeHandler,[])
  const stableMouseHandler = useCallback(mouseCxHandler,[])
  const stableCreateTimes = useCallback(createTimes,[])
  const stableGoogleCalendarHandler = useCallback(googleCalendarHandler,[])
  const stableMoveToLeft = useCallback(moveToLeft,[])
  const stableMoveToRight = useCallback(moveToRight,[])
  const stableUpdateBtns = useCallback(updateBtns,[])



  const ref = useRef();

  const formStyles = {
    control: styles => (
      { ...styles,
        borderRadius:'0',
        boxShadow:'none ',
       }
    ),



    container: styles => (
      { ...styles,
        fontSize: '.8em',
        backgroundColor: '000',
        width:'100%',
      }
    )
  };



  useEffect(() => {
    stableUpdateBtns()
  },[times, stableMoveToRight, stableMoveToLeft,stableUpdateBtns])


  useEffect(() => {
    stableCreateTimes()
  },[current_date,stableCreateTimes])

  useEffect(() => {

    CsrfActions.getCsrf();
    GroupStore.addChangeListener(stableGroupChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    PersonalStore.addChangeListener(stableCurrentDateHandler,"current_date")
    GoogleCalendarStore.addChangeListener(stableGoogleCalendarHandler)
    MouseStore.addChangeListener(stableMouseHandler,"update_x")
    GroupActions.get(id)
    PersonalActions.createNew()
    GoogleCalendarActions.checkAuto();


    //VH設定 for スマホ
    const vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // window resize
    window.addEventListener('resize', () => {
        const _vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${_vh}px`);
    });




    return function cleanup() {
      MouseStore.removeChangeListener(stableMouseHandler,"update_x")
      GroupStore.removeChangeListener(stableGroupChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      PersonalStore.removeChangeListener(stableCurrentDateHandler,"current_date")
      GoogleCalendarStore.removeChangeListener(stableGoogleCalendarHandler)
    };
  },[stableMouseHandler, stableErrorChangeHandler,stableGroupChangeHandler,stableCurrentDateHandler,stableGoogleCalendarHandler,id]);


  useOutsideClick(ref, () => {
    GroupActions.updatePersonalValue(null, "type" ,"static")
  });

  function changeHandler(e){
    PersonalActions.updateCurrentDate(e)
  }
  function changeHandlerBySelect(e){
    PersonalActions.updateCurrentDate(e.value)
  }

  function mouseCxHandler(){
    updateBtns()
  }

  function googleCalendarHandler(){
    /** if(GoogleCalendarStore.getList().length > 0){
      setGoogleBtn(<div className="google-calendar-message">Google Calendarを読み込みました。</div>)
      setGoogleCalendar(<GoogleCalendar />)
      setTimeLineGoogleClass("with-google")
    } **/
  }

  function updateBtns(){
    const diff = window.document.getElementById("time-line-info").scrollWidth - window.document.getElementById("time-line-info").clientWidth

    if(diff - MouseStore.getCurrentX() > 0){
      setBtnRight(<div className="move_btn to_right"  ><i className="fas fa-chevron-circle-right" onPointerUp={stableMoveToRight}></i></div>)
    }else{
      setBtnRight(null)
    }

    if(MouseStore.getCurrentX() > 0){
      setBtnLeft(<div className="move_btn to_left" ><i className="fas fa-chevron-circle-left" onPointerUp={stableMoveToLeft}></i></div>)
    }else{
      setBtnLeft(null)
    }
  }

  function createTimes(){
    const _item = GroupStore.getItem()

    if(_item && _item.items){

      const _times = _item.items.map(
        time_item => {
          return   <TimeCalendar key={"timecalendar_" + time_item.id} current_date={PersonalStore.getCurrentDate()} id={id} item={time_item} type=""  />
        }
      )
      setTimes(_times)
    }
  }

  function errorChangeHandler(){
    setMessage(<div className="message right">{ErrorStore.getMessage()}</div>)
  }

  function groupChangeHandler(){
    const _item = GroupStore.getItem()

    if(_item){
      setTitle(_item.group.title)
      setComment(_item.group.comment)

      setDeadLineDate(_item.group.deadline_date  ? format(Date.parse(_item.group.deadline_date), 'M月d日(eee)',{locale:ja}) : "-")

      const _duration = _item.group.duration
      const h = Math.floor(_duration / 60 )
      const m = Math.floor((_duration % 60) * 1)
      setDuration(String(h) + "時間" + (m > 0 ? (m + "分") : ""))
      const _day_list = _item.group.date_list.split(",")

      _day_list.sort(function(a, b) {
        if (new Date(a).getTime() > new Date(b).getTime()) {
          return 1;
        } else {
          return -1;
        }
      })

      const _days = _day_list.map(d => {
        const select_class = isSameDay(PersonalStore.getCurrentDate(), new Date(d)) ? "selected" : ""
        const target_date = new Date(d)
        return <div key={"date_" + target_date.toString()} className={"date-item " + select_class} onClick={() => changeHandler(target_date)}>{format(target_date, 'M月d日(eee)',{locale:ja})}</div>
      })
      setSelecter(
        _days
      )
      setDateList(
        _day_list.map(d => {
          const target_date = new Date(d)
          const dey_item = {value: target_date, label:format(target_date, 'M月d日(eee)',{locale:ja})}

          return dey_item
        })
      )
      createTimes()
      if(!TutorialStore.hasClosed("time_select") && !TutorialStore.hasClosed("all")){
          setTutolial(<Tutorial id="time_select" closeHandler={closeTutolial} content="入力する日付を選択します"/>)

      }
    }
    setMessage(null)
  }
/**
  function googleBtnHandler(){

    if(GoogleCalendarStore.isAuth()){
      GoogleCalendarActions.getList()
    }else{
      GoogleCalendarActions.auth()
    }
  }
**/
  function currentDateHandler(){
    setCurrentDate(PersonalStore.getCurrentDate())
    groupChangeHandler()
  }

  function onScrollEvent(e){
    window.document.getElementById("time_line_label").style.top =  (-1 * e.currentTarget.scrollTop) + "px"
    if(window.document.getElementById("time_line_google")){
      window.document.getElementById("time_line_google").style.top =  (-1 * e.currentTarget.scrollTop) + "px"
    }
  }


  function moveToRight(){
    if(!MouseStore.getIsMoving()){
      MouseActions.setIsMoving(true)
      var  target_dif = MouseStore.getCurrentX() + 115

      if(window.document.getElementById("time-line-info").scrollWidth - target_dif <=  window.document.getElementById("time-line-info").clientWidth){
        target_dif = window.document.getElementById("time-line-info").scrollWidth - window.document.getElementById("time-line-info").clientWidth
      }

      SmoothScroll.scrollHorizontalByMargin( target_dif - 1, window.document.getElementById("time-line-items"), MouseStore.getCurrentX())
      SmoothScroll.scrollHorizontal( target_dif, window.document.getElementById("time-line-info"))
      SmoothScroll.scrollHorizontal( target_dif, window.document.getElementById("time-line-btn")).then(() => {
          MouseActions.setIsMoving(false)
      });
      MouseActions.setCurrentX(target_dif)
    }
  }

  function moveToLeft(){

    if(!MouseStore.getIsMoving()){
      MouseActions.setIsMoving(true)
      var target_dif = MouseStore.getCurrentX() - 115
      if(target_dif < 0 ){
        target_dif = 0
      }
      SmoothScroll.scrollHorizontalByMargin(target_dif -1 , window.document.getElementById("time-line-items"), MouseStore.getCurrentX())
      SmoothScroll.scrollHorizontal( target_dif, window.document.getElementById("time-line-info"))
      SmoothScroll.scrollHorizontal( target_dif, window.document.getElementById("time-line-btn")).then(() => {
          MouseActions.setIsMoving(false)
      });
      MouseActions.setCurrentX(target_dif)
    }

  }

  function closeTutolial(id){
    TutorialActions.addClosed(id)
    setTutolial(null)
    if(id === "time_select" || id === "time_select-mob"){
      setTutolial(<Tutorial id="time-line-info" closeHandler={closeTutolial} content="自分の名前の横にある編集ボタンをクリックして入力を開始します"/>)
    }
    else if(id === "time-line-info"){
      setTutolial(<Tutorial id="time-line-items" closeHandler={closeTutolial} content="空いている時間をなぞって登録！" type="top"/>)
    }
    else if(id === "time-line-items" || id === "all"){
      setTutolial(null)
    }

  }

  return(
    <div className="wrap personal" >
      <Header />

      <main className="personal">

      <section className="personal header is-mob">
        <div className="logo"><img src={image3} alt="スキマ探し"/></div>
        <h1 >{title}</h1>

      </section>
      <section className="personal header is-mob comment">
        {comment}
      </section>


      <section className="personal">
        <section className="content-header is-pc">
          <h1 >{title}</h1>
          <div className="comment">{comment}</div>
          <div className="image3"><img src={image2} alt="イラスト"/></div>
        </section>

        {message}
        <section className="calendar-time" id="time_line">
          <div className="time">
            <div className="duration">予定時間: {duration} </div>
            <div className="duration is-pc">入力締切: {deadline_date} </div>

            <div id="time_select">
              <div className="select is-pc" >
                {selecter}
              </div>

              <Select
                options={date_list}
                placeholder = "候補日を選択"
                onChange = {changeHandlerBySelect}
                styles = {formStyles}
                id="time_select-mob"
                className="is-mob"
                value = {{value: current_date ? current_date : null, label:current_date ? format(current_date, 'M月d日(eee)',{locale:ja}) : null}}
              />
            </div>
          </div>
          <div className={"time-input "} id="time-input" >

            <div className={"time-line-warp "}>
              <TimeCalendarPerson id={id}/>
              <div className="time-line-items" id="time-line-items" onScroll={onScrollEvent}>{times}</div>
              <TimeCalendarBtn id={id}/>
            </div>
            <TimeCalendarLabel  />

            {btn_left}
            {btn_right}
          </div>
        </section>
      </section>
      </main>
        <Footer />
      {tutolial}

    </div>
  )
}

export default GroupCreatedApp
