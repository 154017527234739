import React, { useState, useEffect ,useCallback } from 'react'

import ErrorStore from '../stores/ErrorStore';
import GroupStore from '../stores/GroupStore';
import GroupActions from '../actions/GroupActions';

import Select from 'react-select'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'
import "react-datepicker/dist/react-datepicker.css";

import Input from "./parts/Input"
import Calendar from "./parts/Calendar"
import Header from "./parts/Header"
import Footer from "./parts/Footer"

import image1 from "../images/image_2.svg"
import image2 from "../images/image_1.svg"
import image_sp_1 from "../images/sp_1.svg"
import image_sp_2 from "../images/sp_2.svg"
import image_sp_3 from "../images/sp_3.svg"

import image_content_1 from "../images/content_1.svg"
import image_content_2 from "../images/content_2.svg"
import image_content_3 from "../images/content_3.svg"
import image_step_1 from "../images/step_1.svg"
import image_step_2 from "../images/step_2.svg"
import image_step_3 from "../images/step_3.svg"

import "../styles/create.css"

function GroupCreateApp(props:PropsValue) {

  const [group, setGroup] = useState(null);
  const [duration_list, setDurationList] = useState(0)
  const [name_list, setNameList] = useState(null)
  const [message, setMessage] = useState(null)
  const [btn, setBtn] = useState(<button className="create-btn" onClick={submit} >スケジュールを作成</button>)

  registerLocale('ja', ja)

  const settings = {
      dots: true,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  };
  const formStyles = {
    control: styles => (
      { ...styles,
        borderRadius:'0',
        boxShadow:'none ',
       }
    ),



    container: styles => (
      { ...styles,
        fontSize: '.8em',
        backgroundColor: '000',
        width:'100%',
      }
    )
  };

  const stableGroupChangeHandler = useCallback(groupChangeHandler,[])

  function groupChangeHandler(){
    const _item = GroupStore.getItem()
    setGroup(_item)
    var list = []
    for(var i = 0; i < _item.personals.length; i++){
      const id = i
      list.push(<Input key={"name_" + i } name={"name_" + 1} placeholder="例）スキマ太郎" value={_item.personals[i]} type="text" onChange={(name,value,error) => nameChangeHandler(id,value)} />)
    }

    setNameList(list)
  }

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  function errorChangeHandler(){
    setMessage(ErrorStore.getMessage())
    setBtn(<button className="create-btn" onClick={submit} >スケジュールを作成</button>)
  }



  useEffect(() => {
    //
    //CsrfActions.getCsrf();
    GroupStore.addChangeListener(stableGroupChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    //CsrfStore.addChangeListener(csrfChangeHandler)
    GroupActions.createNew()
    const _duration_list = [{value: 30, label:"30分"}]
    for(var i = 1 ; i < 48; i++){
      const hm = (i + 1 ) %2 === 0 ? ( (i+1)  / 2) + "時間" : ( i  / 2) +"時間30分"
      _duration_list.push({value: 30 * (i + 1), label:hm})
    }
    setDurationList(_duration_list)

    return function cleanup() {
      GroupStore.removeChangeListener(stableGroupChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      //CsrfStore.removeChangeListener(csrfChangeHandler)
    };
  },[stableErrorChangeHandler,stableGroupChangeHandler]);

  function formChangeHandler(name,value,error){
    GroupActions.updateValue(name,value)
  }

  function changeHandler(e){
    GroupActions.updateValue("duration", e.value)
  }

  function nameChangeHandler(id,value){

    const _item = GroupStore.getItem()
    var personals = _item.personals
    personals[id] = value
    GroupActions.updateValue("personals",personals)
  }
  function addMember(){
    const _item = GroupStore.getItem()
    var personals = _item.personals
    personals.push("")
    GroupActions.updateValue("personals",personals)
  }
  function submit(){
    const item = GroupStore.getItem()
    if(!item.title || item.title===""){
      setMessage("タイトルを入力してください。")
      return
    }else if(!item.duration || item.duration===""){
      setMessage("予定時間を入力してください。")
      return
    }else if(!item.date_list || item.date_list.length===0){
      setMessage("候補日を入力してください。")
      return
    }else{
      setBtn(<div className="loading"><i className="fas fa-circle-notch fa-spin"></i></div>)
    }
    setMessage(null)
    GroupActions.create(item)

  }

  function changeSelectdDate(d){
    var _selectd = GroupStore.getItem().date_list
    var is_find = false
    for(var i = 0; i < _selectd.length; i++){
      if(d === _selectd[i]){
        is_find = true
         _selectd.splice(i,1)
        break
      }
    }

    if(!is_find){
      _selectd.push(d)
    }

    GroupActions.updateValue("date_list", _selectd)
  }


  function dateChangeHandler(e){
    GroupActions.updateValue("deadline_date", e)
  }

  return(
    <div className="wrap">
      <Header />
      <div className="descriptions is-pc">
        <div className="description-item">
          <div className="description-title"><img src={image_step_1} alt="ステップ1"/></div>
          <div className="description-content"><img src={image_content_1} alt="説明文" width={"206px"}/></div>
        </div>
        <div className="description-item">
          <div className="description-title"><img src={image_step_2} alt="ステップ2"/></div>
          <div className="description-content"><img src={image_content_2} alt="説明文" width={"187px"}/></div>
        </div>
        <div className="description-item">
          <div className="description-title"><img src={image_step_3} alt="ステップ3"/></div>
          <div className="description-content"><img src={image_content_3} alt="説明文" width={"226px"}/></div>
        </div>
      </div>

      <div className="slider is-mob">
        <Slider {...settings}>
        <div className="slider-image"><img src={image_sp_1} alt="step1"/></div>
        <div className="slider-image"><img src={image_sp_2} alt="step1"/></div>
        <div className="slider-image"><img src={image_sp_3} alt="step1"/></div>
        </Slider>
      </div>


      <main className="GroupCreateApp">
        <div className="image1 is-pc"><img src={image1} alt="イラスト"/></div>
        <div className="image2 is-pc"><img src={image2} alt="イラスト"/></div>
        <section className="create-form">
          <div className="form">

            <div className="list-item">
              <div className="item-head">タイトル</div>
              <div className="item-content">
                <Input name="title" placeholder="例）打ち合わせ" value={group ? group.title : null} type="text" onChange={formChangeHandler} />
              </div>
            </div>

            <div className="list-item">
              <div className="item-head">予定時間</div>
              <div className="item-content">
                <Select
                  options={duration_list}
                  placeholder = "予定時間を選択"
                  onChange = {changeHandler}
                  styles = {formStyles}
                />
              </div>
            </div>

            <div className="list-item">
              <div className="item-head">招待メンバー</div>
              <div className="item-content">
                {name_list}
                <div className="add-btn" onClick={addMember}><i className="fas fa-plus-circle"></i> 招待メンバーを追加</div>
              </div>
            </div>

            <div className="list-item">
              <div className="item-head">締め切り日</div>
              <div className="item-content">
                <DatePicker className="date-expire" showPopperArrow={false} locale="ja" placeholderText="締め切り日(オプション)" selected={group ? group.deadline_date : null} dateFormat="yyyy-MM-dd" onChange={date => dateChangeHandler( date)} />
              </div>
            </div>

            <div className="list-item">
              <div className="item-head">コメント</div>
              <div className="item-content">
                <Input name="comment" placeholder="新規プロジェクトの打ち合わせです。"　value={group ? group.comment : null} type="textarea" onChange={formChangeHandler} />
              </div>

            </div>
            <div className="message">{message}</div>

          </div>
          <div className="calendar">
            <Calendar changeSelect={changeSelectdDate} />
          </div>

        </section>

        <section className="btns">

          {btn}
        </section>




      </main>
      <Footer />
    </div>
  )
}

export default GroupCreateApp
