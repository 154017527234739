import React, {  } from 'react'
import {  useParams} from 'react-router-dom';
import config from 'react-global-configuration';
import Header from "./parts/Header"
import Footer from "./parts/Footer"
import "../styles/create.css"
import createdImg from "../images/img1.png"

function GroupCreatedApp(props:PropsValue) {

  const { id } = useParams();

  function linkToPage(){
    window.location.href = config.get("PERSONAL_URL_WITH_URI") + id
  }

  function copy(){
    const textarea = document.createElement("textarea")
  	textarea.value =  config.get("PERSONAL_URL_WITH_URI") + id
  	document.body.appendChild(textarea)
  	textarea.select()
  	document.execCommand("copy")
  	textarea.parentElement.removeChild(textarea)
  }


  return(
    <div className="wrap" >
      <Header />
      <main className="GroupCreateApp">

        <section className="created">
          <div className="created-img"><img src={createdImg} alt="created"/></div>
          <div className="notice">予定が作成されました。</div>
          <div  className="created-message">以下のURLをコピーして、ラインやメールなどで共有して、<br/>スケジュールを調整しましょう。</div>
          <div className="url-box">

            <div className="url" id="url">{config.get("PERSONAL_URL_WITH_URI") + id}</div>
            <div className="copy" onClick={copy}>コピー</div>
          </div>
        </section>
        <section className="btns">
          <button className="create-btn" onClick={linkToPage} >スケジュール調整ページにアクセス</button>
        </section>
      </main>
        <Footer />
    </div>
  )
}

export default GroupCreatedApp
