import React, {  } from 'react'

import Header from "./parts/Header"
import Footer from "./parts/Footer"


import "../styles/question.css"


function QuestionApp(props:PropsValue) {


  return(
    <div className="wrap" >
      <Header />
      <main className="static">

        <section>
          <h1>お問い合わせ・ご要望</h1>

          <p>問い合わせが送信されました。担当者から返信いたしますので、しばらくお待ちください。</p>

        </section>

      </main>
        <Footer />
    </div>
  )
}

export default QuestionApp
