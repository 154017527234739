import React, { useState , useEffect, useCallback} from 'react'
//import MouseActions from "../../actions/MouseActions"
//import MouseStore from "../../stores/MouseStore"
import GroupStore from "../../stores/GroupStore"
import PersonalStore from "../../stores/PersonalStore"
import GroupActions from "../../actions/GroupActions"

import { format ,add,sub,isSameMinute,setMinutes,setHours } from 'date-fns'


function TimeCalendarItem(props:PropsValue) {

  const [selected, setSelectd] = useState("")
  const [full, setFull] = useState("")
  const [time, setTime] = useState(null)

  const stableUpdateSelected = useCallback(updateSelected,[])
  const stablePersonalHandler = useCallback(personalHandler,[])



  useEffect(() => {

    GroupStore.addChangeListener(stablePersonalHandler)
    PersonalStore.addChangeListener(stablePersonalHandler,"current_date")

    const item = GroupStore.getPersonal(props.item.id)
    if(item.time_list.length > 0){
      stableUpdateSelected(item.time_list)
    }
    return function cleanup() {
      GroupStore.removeChangeListener(stablePersonalHandler)
      PersonalStore.removeChangeListener(stablePersonalHandler,"current_date")
    }
  },[props.item.id,stableUpdateSelected,stablePersonalHandler]);



  function personalHandler(){
    const item = GroupStore.getPersonal(props.item.id)
    if(item){
      updateSelected(item.time_list)
    }
  }



  function updateSelected(list){
    const date = PersonalStore.getCurrentDate()
    const item = GroupStore.getPersonal(props.item.id)
    //テンポラリのarrayを追加

    var is_find = false
    var sorted_list = list.concat(item.tmp_time_list)

    sorted_list.sort(function(a, b) {
      if (new Date(a).getTime() > new Date(b).getTime()) {
        return 1;
      } else {
        return -1;
      }
    })

    //target_time

    const target_time = setMinutes(setHours(date, props.h),props.m)

    const full_list = GroupStore.getFullStartTime ()
    for(var i = 0; i < sorted_list.length; i++ ){


      if(isSameMinute(sorted_list[i],target_time)){
        var full_str = ""
        var target_date = target_time
        //Fullかどうかを決定
        var is_full = false

        for(var c = 0; c < full_list.length; c++){
          if(isSameMinute(full_list[c].start_at, target_date)){
            is_full = true
            break
          }
        }

        if(is_full){
          full_str = "full "
          //一個上にもFullあるかどうかチェック
          const _tmp_target = sub(target_date,{minutes:30})
          var has_before = false
          for(var b = 0; b <full_list.length; b++){
            if(isSameMinute(full_list[b].start_at, _tmp_target)){
              has_before = true
              break
            }
          }
          if(!has_before){
            full_str += ("full-top ")
          }

          //一個下にもFullあるかどうかチェック
          var has_after = false
          const _tmp_target2 = add(target_date,{minutes:30})
          for(var d = 0; d <full_list.length; d++){
            if(isSameMinute(full_list[d].start_at, _tmp_target2)){
              has_after = true
              break
            }
          }
          if(!has_after){
              full_str += ("full-bottom ")
          }
          setFull(full_str)
        }
        else {
          setFull("")
        }

        //最初のコマかどうかの判定
        target_date = sub(target_date,{minutes:30})
        var is_first = true
        for(var t = 0; t < sorted_list.length; t++ ){
          if(isSameMinute(new Date(sorted_list[t]),target_date)){
            is_first = false
            break;
          }
        }

        if(is_first){
          //自分から連続する最後の時間を探す
          var is_curren_date = false
          var current_validated_date = null
          var last_time = null

          for(var k = 0; k < sorted_list.length; k++ ){

            //先ず自分を見つける
            if(!is_curren_date && isSameMinute(new Date(sorted_list[k]), target_time)){
              is_curren_date = true
              current_validated_date = new Date(sorted_list[k])
              current_validated_date = add(current_validated_date,{minutes:30})

              //最後のコマかつ１コマの場合ここで終わり
              if(k === sorted_list.length -1 ){
                last_time = current_validated_date
                //last_time = add(last_time,{minutes:last_time.getTimezoneOffset()})
              }

              continue;
            }

            //自分からみて連続するコマがあればcontinue なければbreak

            if(is_curren_date && isSameMinute(current_validated_date,sorted_list[k])){
              current_validated_date = add(current_validated_date,{minutes:30})
            }

            if(is_curren_date  && k === sorted_list.length -1 ){

              is_curren_date = false
              last_time = current_validated_date
              //last_time = add(last_time,{minutes:last_time.getTimezoneOffset()})
            }
          }

          setTime(<div className="time-display"><div>{props.time +"-" +(last_time ? format(last_time,"HH:mm")  : "")}</div>{item.type === "form"  ? <div className="delete" onClick={deleteItem}><i className="fas fa-times-circle"></i></div> : null}</div>)

        }else{
          setTime(null)
        }

        setSelectd("selected")
        is_find = true
        break

      }
    }

    if(!is_find){
      setSelectd("")
      setFull("")
      setTime(null)
    }

  }



  function deleteItem(){
    GroupActions.deleteSelectedTime(props.item.id,setMinutes(setHours(PersonalStore.getCurrentDate(), props.h),props.m))
  }
  // onPointerUp={onMouseUpHandler} onPointerDown={mouseSelectedHandler}  onMouseEnter={onDragEnterHandler}
  return(
    <div className={"time-line-item " + selected + " " +full + props.add_class}  >
      {time}
    </div>
  )
}

export default TimeCalendarItem
