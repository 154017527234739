import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Personal from "../vo/Personal"

var CHANGE_EVENT = "change"
var CREATED = "created"
var UPDATE_CURRENT_DATE = "current_date"
var UPDATE = "update"
var _item = []
var _current_date = null


var createNew = () => {
  _item = new Personal()
}

var setItem = (item) => {
  _item = (item)
}

var updateItem = (name,value) => {
  _item.update(name,value)
}

var setCurrentDate = (item) => {
  _current_date = (item)
}


var PersonalStore = assign({},EventEmitter.prototype,{


  getItem(){
    return _item
  },

  getCurrentDate(){
    return _current_date
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PERSONAL_UPDATE:
      updateItem(action.name, action.value)
      PersonalStore.emitChange(UPDATE)
    break;

    case Constants.PERSONAL_NEW:
      createNew()
      PersonalStore.emitChange()
    break;

    case Constants.PERSONAL_CREATED:
      setItem(action.item)
      PersonalStore.emitChange(CREATED)
    break;

    case Constants.PERSONAL_SET_DATE:
      setCurrentDate(action.date)
      PersonalStore.emitChange(UPDATE_CURRENT_DATE)
    break;

    default:
  }


})
export default PersonalStore
