import React from 'react'

import GroupActions from "../../actions/GroupActions"
import PersonalActions from "../../actions/PersonalActions"
import CsrfStore from "../../stores/CsrfStore"

import {format, parseISO} from "date-fns"

function TimeCalendarBtnItem(props:PropsValue) {



  function clickHandler(){
    if(props.item.type === "form"){
      GroupActions.updatePersonalValue(props.item.id, "type" ,"static")
      const  csrf = CsrfStore.getItem()
      if(csrf){
        if(props.item.uid){
          PersonalActions.update(props.id, props.item, csrf.uid)
        }else{
          PersonalActions.create(props.id, props.item, csrf.uid)
        }
      }

    }

  }

  return(
    <div className={props.item.type === "form" ? "timeline-update-btn editing" : "timeline-update-btn"} id={"uesr_info_" + props.item.id} onClick={clickHandler}>
      {props.item.type === "form" ?　"登録" :props.item.updated_at ? <span className="sml">{format(parseISO(props.item.updated_at),'M月d日更新')}</span> : <span className="sml">未登録</span>}
    </div>
  )
}

export default TimeCalendarBtnItem
