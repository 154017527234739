import React, { useState,useEffect,useCallback } from 'react'

import Header from "./parts/Header"
import Footer from "./parts/Footer"
import Input from "./parts/Input"
import QuestionStore from "../stores/QuestionStore"
import QuestionActions from  "../actions/QuestionActions"

import "../styles/question.css"


function QuestionApp(props:PropsValue) {

  const [item, setItem] = useState(QuestionStore.getItem());
  const [btn, setBtn] = useState( <button className="create-btn disable" >送&nbsp;&nbsp;信</button>);

  const stableQuestionChangeHandler = useCallback(questionChangeHandler, [])
  const stableSubmit = useCallback(submit, [])
  useEffect(() => {

    QuestionStore.addChangeListener(stableQuestionChangeHandler)
    return function cleanup() {
      QuestionStore.removeChangeListener(stableQuestionChangeHandler)
    };
  },[stableQuestionChangeHandler]);


  useEffect(() => {
    console.log("are")
    if(item.name && item.name !== "" && item.mail && item.mail !== "" && item.content && item.content !== ""){
      setBtn(<button className="create-btn" onClick={stableSubmit} >送&nbsp;&nbsp;信</button>)
    }else{
      setBtn( <button className="create-btn disable" >送&nbsp;&nbsp;信</button>)
    }
  },[item,stableSubmit]);

  function questionChangeHandler(){

    setItem(QuestionStore.getItem())
  }
  function submit(){
    QuestionActions.create(QuestionStore.getItem())
  }
  function formChangeHandler(name,value){

    QuestionActions.updateItem(name,value)
  }

  return(
    <div className="wrap" >
      <Header />
      <main className="static">

        <section>
          <h1>お問い合わせ・ご要望</h1>

          <p>スキマ探しに関してのお問い合わせやご要望はこちらのフォームからご連絡ください</p>

          <div  className="question">
            <div className="question-item">
              <div className="label">お名前</div>
              <div className="item-content">
                <Input name="name" placeholder="お客様のお名前を入力してください" value={item.name} type="text" onChange={formChangeHandler} />
              </div>
            </div>


            <div className="question-item">
              <div className="label">メールアドレス</div>
              <div className="item-content">
                <Input name="mail" placeholder="お客様のメールアドレスを入力してください" value={item.mail} type="text" onChange={formChangeHandler}/>
              </div>
            </div>

            <div className="question-item">
              <div className="label">お問い合わせ内容</div>
              <div className="item-content">
                <Input name="content" placeholder="お問い合わせ内容を入力してください" value={item.content} type="textarea" onChange={formChangeHandler}  />
              </div>
            </div>

          </div>

        </section>
        <section className="btns">

          {btn}
        </section>

      </main>
        <Footer />
    </div>
  )
}

export default QuestionApp
