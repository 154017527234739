import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import GroupActions from "./GroupActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import { formatISO } from 'date-fns'

var PersonalActions = {



  create(id){

    const  request_data = {
      "name":"",
      "comment":"",
      "start_at":"",
      "csrf":"",
      "group_id":id,
    }


    fetch(config.get("API_PATH") + config.get("API_PERSONAL"), {
      method: "POST",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        GroupActions.get(id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_CREATE_GROUP")
        })
      }
    })
  },

  update(id,item,csrf){

    const  request_data = {
      "name":item.name,
      "comment":item.comment,
      "start_at":item.time_list.map(d=>{return formatISO(d)}).join(","),
      "csrf":csrf,
      "group_id":id,
    }


    fetch(config.get("API_PATH") + config.get("API_PERSONAL") + "/" + item.uid, {
      method: "PATCH",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        GroupActions.get(id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_CREATE_GROUP")
        })
      }
    })
  },


  createNew(){
    AppDispatcher.dispatch({
      actionType: Constants.PERSONAL_NEW,
    })
  },


  setNameError(){
    AppDispatcher.dispatch({
      actionType: Constants.API_ERROR,
      message:"名前を入力してください"
    })
  },
  updateValue(name,value,id){
    AppDispatcher.dispatch({
      actionType: Constants.PERSONAL_UPDATE,
      name:name,
      value:value,
      id:id
    })
  },

  updateCurrentDate(value){
    AppDispatcher.dispatch({
      actionType: Constants.PERSONAL_SET_DATE,
      date:value
    })
  },


}

export default PersonalActions
