import { Record } from 'immutable'


const Group = Record({
  title:null,
  created_name:null,
  created_mail: null,
  duration:null,
  comment:null,
  date_list:[],
  personals:["",""],
  deadline_date:null
});
export default Group  ;
