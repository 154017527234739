import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  MESSAGE_CREATE:null,

  CSRF_LOADED:null,

  GROUP_NEW:null,
  GROUP_UPDAE:null,
  GROUP_CREATED:null,
  GROUP_LOADED:null,
  ITEM_PERSONAL_UPDATE :null,

  PERSONAL_NEW:null,
  PERSONAL_UPDATE:null,
  PERSONAL_CREATED:null,
  PERSONAL_SET_DATE:null,

  CURRENT_DAY_UPDATE:null,

  SET_DRAGGING:null,
  SET_START_TIME:null,
  SET_ENTER:null,
  SET_IS_MOVING:null,
  SET_CURRENT_X:null,

  DETERMIN_SELECT:null,
  DELETE_SELECTED_TIME:null,
  SET_SCRIOLL:null,

  GCA_AITH_SET:null,
  GCA_LIST_LOADED:null,

  TUTORIAL_CLOSED:null,

  UPDATE_QUESTION:null,

  API_ERROR:null
})
