import React, { useState , useEffect, useCallback} from 'react'
import config from 'react-global-configuration';
import TimeCalendarItem from "./TimeCalendarItem"
import MouseActions from "../../actions/MouseActions"
import GroupActions from "../../actions/GroupActions"
import GroupStore from "../../stores/GroupStore"


import MouseStore from "../../stores/MouseStore"
import PersonalStore from "../../stores/PersonalStore"

import {addMinutes,setMinutes,setHours} from "date-fns"
import Draggable from 'react-draggable'; // Both at the same time

function TimeCalendar(props:PropsValue) {
  const [timeline, setTimeLine] = useState(null)
  const [type, setType] = useState(props.type)
  const [draggpoint, setDraggPoint] = useState(null)

  const stablePersonalHandler = useCallback(personalHandler,[])
  const stableUpdateTimeLine = useCallback(updateTimeLine,[])



  useEffect(() => {
    GroupStore.addChangeListener(stablePersonalHandler)
    stableUpdateTimeLine()
    return function cleanup() {
      GroupStore.removeChangeListener(stablePersonalHandler)

    }

  },[stablePersonalHandler,stableUpdateTimeLine]);


  useEffect(() => {
    stableUpdateTimeLine(type)
  },[type,stableUpdateTimeLine]);



  function onMouseUpHandler(){
    MouseActions.setDragging(false)
  }

  function personalHandler(){
    const item = GroupStore.getPersonal(props.item.id)
    setType(item.type)
    if(item.type === "form" ){
      setDraggPoint(
        <Draggable
           axis="y"
           handle=".handle"
           defaultPosition={{x: 0, y:0}}
           position={null}
           grid={[115, 18]}
           scale={1}
           onStart={handleStart}
           onDrag={handleDrag}
           onStop={handleStop}
           >
           <div>
             <div className="handle">Drag from here</div>
           </div>
         </Draggable>
      )
    }else{

      setDraggPoint(null)
    }
  }


  function updateTimeLine(_type){
    var counter = 0
    setTimeLine(
      (new Array(25 * 2)).fill(null).map( w => {
        counter ++
        var h = counter % 2 === 0 ? ((counter / 2) -1) : Math.floor(counter / 2 )
        if(h < 10) h = "0" + h
        const time = (counter % 2 === 0 ? h + ":30" : h + ":00")
        const border_class = (counter % 2 === 0 ? "dotted-border" : "")
        return <TimeCalendarItem key={"citem_" + PersonalStore.getCurrentDate() + "_"+ counter} item = {props.item} add_class = { border_class} time = {time} h = {h} m = {counter % 2 === 0 ? 30 : 0} type={_type} />
      })
    )
  }

  function handleStart(e, data){
    const item = GroupStore.getPersonal(props.item.id)
    if(item.type === "form"){
      const c_y = e.changedTouches && e.changedTouches.length > 0 ? e.changedTouches[0].clientY : e.pageY
      MouseActions.setDragging(true)
      const new_date = getSelectedDateTime(c_y)
      console.log(new_date)
      MouseActions.setStartTime(new_date)

    }
  }
  function handleDrag(e){
    if(MouseStore.getDragging()){
      const c_y = e.changedTouches && e.changedTouches.length > 0 ? e.changedTouches[0].clientY : e.pageY
      const new_date = getSelectedDateTime(c_y)
      GroupActions.updatePersonalValue(props.item.id, "tmp_time_list", new_date)
    }
  }
   function handleStop(e){
    if(MouseStore.getDragging()){
      if(MouseStore.getStartTime() ){
        const c_y = e.changedTouches && e.changedTouches.length > 0 ? e.changedTouches[0].clientY : e.pageY
        const new_date = getSelectedDateTime(c_y)
        GroupActions.updatePersonalValue(props.item.id, "tmp_time_list", new_date)
        GroupActions.determinSelect(props.item.id)
        MouseActions.setStartTime(null)
      }
      MouseActions.setDragging(false)
      setDraggPoint(
        <Draggable
           axis="y"
           handle=".handle"
           defaultPosition={{x: 0, y:0}}
           position={{x: 0, y:0}}
           grid={[115, 18]}
           scale={1}
           onStart={handleStart}
           onDrag={handleDrag}
           onStop={handleStop}
           >
           <div>
             <div className="handle">Drag from here</div>
           </div>
         </Draggable>
      )
    }
  }



  function getSelectedDateTime(y){
    const current_h = y - config.get("ITEM_OFFSET") - window.document.getElementById("time-line-items").getBoundingClientRect().y + window.document.getElementById("time-line-items").scrollTop
    const number = Math.ceil(current_h / config.get("ITEM_HEIGHT"))
    const time = PersonalStore.getCurrentDate();
    const new_date =addMinutes(setMinutes(setHours(time,0),0), (number - 1) * 30)
    return new_date
  }

  return(
    <div className={"tlbox " + type} >
      <div className="time-line-box">
        <div  className="time-line" onMouseLeave={onMouseUpHandler}  >
        {draggpoint}
        {timeline}
        </div>
      </div>

    </div>
  )
}

export default TimeCalendar
