import React, { useState , useEffect,useCallback} from 'react'
import GroupStore from "../../stores/GroupStore"
import GroupActions from "../../actions/GroupActions"
import PersonalActions from "../../actions/PersonalActions"
import Input from "./Input"

function TimeCalendarPerson(props:PropsValue) {

  const [items, setItems] = useState([])
  const stableGroupChangeHandler = useCallback(groupChangeHandler,[])
  useEffect(() => {
    GroupStore.addChangeListener(stableGroupChangeHandler)
    return function cleanup() {
      GroupStore.removeChangeListener(stableGroupChangeHandler)
    }
  //  createItems()

},[props.items,stableGroupChangeHandler]);


  function groupChangeHandler(){
    createItems()
  }

  function editHandler(id){
    GroupActions.updatePersonalValue(id, "type" ,"form")
  }

  function editName(name,value,id){
    GroupActions.updatePersonalValue(id, "name" ,value)
  }

  function addPerson(){
    PersonalActions.create(props.id)
  }

  function createItems(){
    const _item = GroupStore.getItem()

    if(_item && _item.items){
      setItems(
        _item.items.map(
          item => {
            return (
              <div className={item.type === "form" ? "user-info editing" : "user-info"} id={"uesr_info_" + item.id} key={"uesr_info_" + item.id}>
              { item.type === "form" ?
               <Input name="name" placeholder="スキマ太郎" onChange={(name,value) => editName(name,value,item.id)} value={item.name} type="text"  />
                :
                <div className="name">{item.name} &nbsp;&nbsp;<i className="fas fa-edit" onClick={() => editHandler(item.id)}></i></div>
              }
              </div>
            )
          }
        )

      )
    }
  }

  return(
      <div className="time-line-info" id="time-line-info">
        {items}
        <div className="user-info add-person" onClick={addPerson}><i className="fas fa-user-plus"></i>&nbsp;<span>追加</span></div>
      </div>
  )
}

export default TimeCalendarPerson
