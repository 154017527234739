import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'



var CsrfActions = {

  getCsrf(){

    AppDispatcher.dispatch({
      actionType: Constants.CSRF_LOADED,
      csrf:"sukimaaduj"
    })

  },



}

export default CsrfActions
