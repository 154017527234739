import React, {  } from 'react'


import Header from "./parts/Header"
import Footer from "./parts/Footer"

import "../styles/create.css"


function RulesApp(props:PropsValue) {



  return(
    <div className="wrap" >
      <Header />
      <main className="static">

        <section>
          <h1>利用規約</h1>
          <p>スキマさがし （以下「本サイト」といいます）は、株式会社アンシーク（以下「当社」といいます）が提供するサービスです。利用者の皆様は以下の各項目について承諾のうえ、本サイトをご利用下さい。なお、当社は、利用者の皆様が本サイトを利用することをもって、本利用規約に同意したものとみなします。</p>
          <h2>第1条（本サイトの運営）</h2>
<p>
1.当社は、以下の条件に基づき本サイトを運営いたします。利用者の皆様は、以下の条件を十分認識・ご承諾の上、本サイトを利用するものとします。<br/>
（１） 本サイトは、当社がネット上のマーケティング活動を行うことを目的として、一定期間のみ、利用者の皆様に提供する実験サービスです。あくまで実験サービスであり、当社は、利用者の皆様に対し、本サイトのシステム・設計・動作環境等につき、何らの保証を行うものではありません。また、当社は、マーケティング活動が終了した場合もしくは当社が必要と判断した場合には、第6条第2項に基づき、何ら利用者の皆様への予告を行うことなく、本サイトのシステム・設計のうち、一部または全部につき、変更または廃止することがございます。<br/>
（２） 本サイトにおいて、商品やサービスに関する情報（以下「本情報」といいます）が掲載されている場合、本情報は、当社以外の第三者（以下「情報提供者」といいます）から提供を受けております。本情報は全て情報提供者の責任に基づくものであり、本サイトのご利用に際し、利用者の皆様にとって重要な事項は、必ず利用者の皆様ご自身の責任において、情報提供者に直接ご確認ください。<br/>
（３） 当社は、本サイトにおいて明示的に保証されるものを除き、以下に関する事項も含め、何らの保証もいたしません。<br/>
① 本情報の有用性、適合性、完全性、正確性、安全性、合法性、最新性および真実性等。<br/>
② 本サイトまたは本サイトを通じて入手できる商品、役務および情報等が利用者の皆様の希望を満たすこと。<br/>
③ 利用者の皆様が本サイト上に提供される情報を利用して第三者と締結された契約（通信販売サービスにおける売買契約等を含みます）。<br/>
④ 本サイトの提供に不具合、エラーおよび障害等が生じないこと。また、本サイトに関連して送信される電子メールおよびコンテンツ等に、コンピュータウィルス等の有害なものが含まれていないこと。<br/>
⑤ 本サイト中に含まれる利用者の皆様等の発言またはその他の行為が、第三者の権利を侵害しないこと。
</p>
<h2>第2条（アカウント）</h2>
<p>
１． ユーザーは、本サービスの利用に際してユーザーご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報を登録するものとします。<br/>
２． ユーザーは、本サービスの利用に際してパスワードを登録する場合、これを第三者に不正に利用されないようご自身で厳重に管理しなければなりません。当社は、登録されたID及びパスワードを利用して行われた本サービス上の一切の行為を、ユーザー自身の行為とみなすことができます。<br/>
３． 本サービスに登録したユーザーは、いつでもアカウントを削除して退会することができます。<br/>
４． 当社は、ユーザーが本規約に違反しまたは違反するおそれがあると認めた場合、あらかじめユーザーに通知することなく、アカウントを停止または削除することができます。<br/>
５． 当社は、最終のアクセスから１年間以上経過しているアカウントを、あらかじめユーザーに通知することなく削除することができます。<br/>
６． ユーザーの本サービスを利用する権利のすべては、理由を問わず、アカウントが削除された時点で消滅します。ユーザーが誤ってアカウントを削除した場合であっても、アカウントの復旧はできません。<br/>
７． 本サービスのアカウントは、ユーザーに一身専属的に帰属します。ユーザーの本サービスにおける全ての利用権は、第三者に譲渡、貸与または相続させることはできません。
</p>
<h2>第3条（マイページ上のイベント履歴）</h2>
<p>
前条に基づき登録を行ったユーザーは、登録以降ログインしてイベントを作成した場合に限り、マイページ上で当該イベントを履歴として確認することができるものとします。
</p>
<h2>第4条（著作権）</h2>
<p>
本サイトに掲載されている文章、画像、映像、音声、プログラム等のコンテンツ（本情報を含み、以下同様とします。）についての著作権等の権利は、当社または情報提供者に帰属するものとします。利用者の皆様は、当社の事前の承諾なく、これらのコンテンツの複製または転載等の使用をしてはならないものとします。
</p>
<h2>第5条（禁止事項）</h2>
<p>
利用者の皆様は、本サイトを利用するにあたり、以下に掲げる行為を行ってはならないものとします。<br/>
（１） 本サイト上の情報・メッセージ・写真等を無断で改ざん、消去する等不正にアクセスする行為。<br/>
（２） 本サイトに有害なコンピュータプログラム等を書き込むまたは送信する行為。<br/>
（３） 本サイトの運営を妨げるような行為。<br/>
（４） 法令、公序良俗に違反するまたはそのおそれがある反社会的な行為。<br/>
（５） 第三者または当社の、通信の秘密、財産、プライバシー、肖像権、名誉または信用を侵害したり、誹謗中傷する行為。<br/>
（６） 第三者または当社の著作権・商標権等の知的財産権を侵害するまたはそのおそれがある行為。<br/>
（７） 前各号に定めるほか、本サイトの運営に支障を与え、または第三者・当社の権利・利益を害するもしくはそのおそれのある一切の行為。
</p>
<h2>第6条（本サイトの一時的な停止、変更または廃止）</h2>
<p>
1.当社は、以下の事由に該当すると判断した場合には、事前の通知や承諾なしに、本サイトの一時的な中断を行うことがあります。<br/>
（１） システムの保守または変更を行う場合。<br/>
（２） 天災事変その他非常事態が発生し、または発生するおそれがあり、本サイトの運営が困難な場合。<br/>
（３） その他当社が必要やむをえないと認めた場合。<br/>
2.本サイトの情報、URLは、予告なしに変更または廃止される場合があります。
</p>
<h2>第7条（免責）</h2>
<p>
1.当社は、次の各号の場合において、一切その責任を負わないものとします。<br/>
（１） 本サイトを介して行う、第三者が提供するコンテンツのダウンロード、および、第三者が管理・運営するリンクサイトへのアクセス等の行為により、利用者に生じた損害。<br/>
（２） 当社が合理的な安全策を講じたにもかかわらず、本サイトの無断改変、本サイトに関するデータへの不正アクセス、コンピュータウィルスの混入等の不正行為が行われ、これに起因して利用者に生じた損害。<br/>
（３） その他本サイトの利用（これらに伴う当社による情報提供行為等を含みます）若しくは不利用により生じる一切の損害（精神的苦痛、またはその他の金銭的損失を含む一切の不利益）。<br/>
2.前項各号において、万一当社が責任を負う場合であっても、当社が責任を負うのは当社に故意または重過失がある場合に限るものとし、またその責任範囲は直接かつ通常の損害に限られるものとします。
</p>
<h2>第8条（反社会的勢力の排除）</h2>
<p>
1.お客様は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。<br/>
（１） 暴力団員等が経営を支配または実質的に関与していると認められる団体その他これらに準ずる者と関係を有すること<br/>
（２） 自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること<br/>
（３） 暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること<br/>
2.お客様は、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします<br/>
（１） 暴力的な要求行為<br/>
（２） 法的な責任を超えた不当な要求行為<br/>
（３） 取引に関して、脅迫的な言動をし、または暴力を用いる行為<br/>
（４） 風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為<br/>
（５） その他前各号に準ずる行為
</p>
<h2>第9条（準拠法および管轄裁判所）</h2>
<p>
本利用規約の準拠法は日本法とし、本サイトおよび本利用規約に関する一切の紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
</p>
<p>
附則<br/>
2020年6月6日施行
</p>


        </section>

      </main>
        <Footer />
    </div>
  )
}

export default RulesApp
