import React, { useState , useEffect,useCallback} from 'react'
import GroupStore from "../../stores/GroupStore"

import TimeCalendarBtnItem from "./TimeCalendarBtnItem"

function TimeCalendarBtn(props:PropsValue) {

  const [items, setItems] = useState([])
  const stableGroupChangeHandler = useCallback(groupChangeHandler,[])
  useEffect(() => {
    GroupStore.addChangeListener(stableGroupChangeHandler)
    return function cleanup() {
      GroupStore.removeChangeListener(stableGroupChangeHandler)
    }
  },[props.items,stableGroupChangeHandler]);


  function groupChangeHandler(){
    createItems()
  }

  function createItems(){
    const _item = GroupStore.getItem()

    if(_item && _item.items){

      setItems(
        _item.items.map(
          item => {
            return (
              <TimeCalendarBtnItem item={item} gid={_item.group.id} id={props.id} key={"time_btn_" + item.id}/>
            )
          }
        )
      )
    }
  }

  return(
      <div className="time-line-btn" id="time-line-btn">
        {items}
      </div>
  )
}

export default TimeCalendarBtn
