import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'



var TutorialActions = {

  addClosed(id){
    AppDispatcher.dispatch({
      actionType: Constants.TUTORIAL_CLOSED,
      id:id
    })
  },



}

export default TutorialActions
