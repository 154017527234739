import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


var CHANGE_EVENT = "change"


var _item = null


var setItem = (item) => {
  _item = (item)
}


var CsrfStore = assign({},EventEmitter.prototype,{


  getItem(){
    return _item
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CSRF_LOADED:

      setItem(action.csrf)
      CsrfStore.emitChange()
    break;


    default:
  }


})
export default CsrfStore
